
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Card, IconButton, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DataTable from '../../examples/Tables/DataTable';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import MDBadge from '../../components/MDBadge';
import { isTokenExpired, logout } from '../../utils/authUtils';


import { Watermark } from 'antd';

import Chamber_SaaS_Logo_Transparent from '../../assets/Chamber_SaaS_Logo_Transparent.png';
import Grey_dark from '../../assets/Chamber_SaaS_Logo_Grey_Dark.png';
import Grey_light from '../../assets/Chamber_SaaS_Logo_Grey_Light.png';
interface Chamber {
  chamber_id: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  payment: string;
  organization_name: string;
  organization_category: string;
  payment_status: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Chamber: React.FC = () => {
  const [chambers, setChambers] = useState<Chamber[]>([]);
  const setLoading = useState<boolean>(false)[1];
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [chamberToDeleteId, setChamberToDeleteId] = useState<number | null>(null);
  const authToken = localStorage.getItem("token");
  const {enqueueSnackbar} = useSnackbar();
  const fetchAllChambers = async () => {
    setLoading(true);
    if (isTokenExpired(authToken)) {
      enqueueSnackbar("Login is expired, Sign-in again to continue", {variant: "error"});
        setTimeout(() => {
          logout();
      }, 3000); 
    }
    try {
      const response = await axios.get<{ success: boolean; data: Chamber[] }>(`${process.env.REACT_APP_BASEURL}/chamber`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.data.success) {
        setChambers(response.data.data);
        console.log(authToken);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  
    setLoading(false);
  };
  
  useEffect(() => {
    const currentPath = window.location.pathname;
  
    if (currentPath === '/chamber') {
      fetchAllChambers();
    }
// eslint-disable-next-line
  },[]);

  const columns = [
    // { Header: 'Chamber ID', accessor: 'chamber_id' },
    { Header: 'First Name', accessor: 'first_name' },
    { Header: 'Last Name', accessor: 'last_name' },
    { Header: 'Phone Number', accessor: 'phone_number' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'payment', accessor: 'payment'},
    {
      Header: 'Payment Status',
      accessor: 'payment_status',
      Cell: ({ row }: { row: { original: Chamber } }) => (
        row.original.payment_status ? <MDBadge {...({} as any)} badgeContent="Active" color="success" size="lg" /> : <MDBadge {...({} as any)} badgeContent="Inactive" color="error" size="lg" />
      ),
    },    { Header: 'Organization', accessor: 'organization_name' },
    { Header: 'Org Category', accessor: 'organization_category' },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }: { row: { original: Chamber }}) => (
        <div>
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => handleEdit(row.original.chamber_id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => handleDelete(row.original.chamber_id)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleDelete = (chamber_id: number) => {
    setChamberToDeleteId(chamber_id);
    setDeleteDialogOpen(true);
  };
  
  const handleEdit = (chamber_id: number) => {
    navigate('/chamber/edit', { state: { type: 'edit', chamber_id } });
  };
  
  
  const confirmDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASEURL}/chamber/${chamberToDeleteId }`, 
        { 
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      
      enqueueSnackbar("Chamber deleted successfully!", { variant: 'success' });
  
      setChambers((prevChambers) => prevChambers.filter(chamber => chamber.chamber_id !== chamberToDeleteId));
    } catch (error) {
      enqueueSnackbar('Error deleting chamber:', { variant: 'error' });
    }
  
    setDeleteDialogOpen(false);
  };
  
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Watermark
        height={256}
        width={256}
        // height={512}
        // width={512}
        image={Grey_dark}
        zIndex={-1}
        gap={[1000, 1000]}
      // style={{opacity:"0.5"}}
      > */}
      <Box pt={6} pb={3}>
      <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${Chamber_SaaS_Logo_Transparent})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: 0.7, // Set opacity for background image
            zIndex: -1 // Ensure background is behind the content
          }}
        />
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h6"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                    Chamber
                  </Typography>

                  <Button
                    onClick={() => navigate('/chamber/add')}
                    variant="outlined"
                    sx={{ 
                      mb: 2,
                      mr: 1, 
                      color: "#000", 
                      border: "1px black solid", 
                      "&:hover": { 
                        color: "#6c5ce7",
                        border: "2px #6c5ce7 solid"
                      }}}
                  >
                    Add Chamber
                  </Button>
                </Box>
                {chambers && (
                  <DataTable
                    table={{ columns, rows: chambers }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this chamber?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={confirmDelete} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    {/* </Watermark> */}
    </DashboardLayout>
  );
}

export default Chamber;
