import { ChangeEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// @mui material components
import { CircularProgress, Switch, Typography, Box, Button, TextField, Link, Card, IconButton, InputAdornment } from "@mui/material";


// Images
import bgImage from "../../assets/SaaS_SignIn.png";
import chamberLogo from "../../assets/chamber_logo.png";

function SignIn(): JSX.Element {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const handleClickShowPassword = (): void => setShowPassword((show) => !show);


  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const password = params.get('password');

    if (email && password) {
      setLoading(true);
      const login = async () => {
        try {
          const response = await axios.post<SignInResponse>(`${process.env.REACT_APP_BASEURL}/chamber/login`, {
            email,
            password
          });
          const { accessToken, user_id } = response.data.message;
          localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("role", "superadmin");
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("token", accessToken);
          enqueueSnackbar('sign-in successful', { variant: 'success' });
          navigate('/dashboard');
        } catch (error) {
          console.error('Sign-in error:', error);
          enqueueSnackbar('Error during sign-in', { variant: 'error' });
        } finally {
          setLoading(false);
        }
      };

      login();
    }
  }, []);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  interface SignInResponse {
    message: any;
  }

  const onSignIn = async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await axios.post<SignInResponse>(`${process.env.REACT_APP_BASEURL}/chamber/login`, {
        email,
        password
      });

      const { accessToken, user_id } = response.data.message;
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("role", "superadmin");
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("token", accessToken);
      enqueueSnackbar('sign-in successful', { variant: 'success' });
      navigate('/dashboard');
    } catch (error) {
      console.error('Sign-in error:', error);
      enqueueSnackbar('Error during sign-in', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="stretch"
      minHeight="100vh"
      sx={{ backgroundColor: "#f5f5f5" }}
    >
      <Box
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "3rem",
          backgroundColor: "#fff",
          position: "relative",
          marginTop: "-8rem",
        }}
      >

        <Card
          sx={{
            width: "100%",
            maxWidth: "400px",
            padding: "2rem",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Box display="flex" justifyContent="center" sx={{ marginBottom: "1rem" }}>
            <img src={chamberLogo} alt="Chamber Logo" style={{ maxWidth: "150px", height: "auto" }} />
          </Box>
          <Typography variant="h3" align="center" fontWeight="bold" gutterBottom sx={{ marginBottom: "2rem", color: "#6c5ce7" }}>
            Welcome, Back!
          </Typography>
          <Typography variant="subtitle2" align="center" sx={{ marginBottom: "2rem", color: "#6c757d" }}>
            Enter your email and password to sign in
          </Typography>

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            value={password}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            sx={{ marginBottom: "1rem" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: "1rem" }}>
            <Box display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} color="primary" />
              <Typography variant="body2" sx={{ marginLeft: "0.5rem" }}>
                Remember me
              </Typography>
            </Box>
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#6c5ce7",
              color: "#fff",
              padding: "0.75rem",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "#5e4cc7" },
            }}
            onClick={onSignIn}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
          </Button>
        </Card>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            padding: "1rem",
            color: "#6c757d",
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="body2">
            Made by <Link href="https://pixaflip.com/" target="_blank" sx={{ color: "#6c5ce7", textDecoration: "none" }}>Pixaflip</Link>
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: "50%",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </Box>
  );
}

export default SignIn;