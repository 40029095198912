import {jwtDecode} from 'jwt-decode';

export function isTokenExpired(token) {
    if (!token) return true;
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 < Date.now();
}

export function logout() {
    localStorage.removeItem('token'); 
    localStorage.removeItem('role');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user_id');
    window.location.href = "https://app.pixacci.com/landing-page";
}
